const state = {
  theme: 'light',
  isBrightness: 0,
  resizer: 400,
  sizeChatList: 'default'
}

const mutations = {
  SET_THEME (state, payload) {
    state.theme = payload
  },
  SET_RESIZER (state, payload) {
    state.resizer = payload
  },
  TOGGLE_BRIGHTNESS (state) {
    state.isBrightness = state.isBrightness === 2 ? 0 : state.isBrightness + 1
  },
  SET_SIZECHATLIST (state, payload) {
    state.sizeChatList = payload
  }
}

const actions = {
  setTheme ({ commit }, payload) {
    commit('SET_THEME', payload)
  },
  setResizer ({ commit }, payload) {
    commit('SET_RESIZER', payload)
  },
  toggleBrightness ({ commit }) {
    commit('TOGGLE_BRIGHTNESS')
  },
  setSizeChatList ({ commit }, payload) {
    commit('SET_SIZECHATLIST', payload)
  }
}

const getters = {
  theme: (state) => state.theme,
  resizer: (state) => state.resizer,
  isBrightness: (state) => state.isBrightness,
  sizeChatList: (state) => state.sizeChatList
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
