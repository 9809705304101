import Vue from 'vue'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import customParseFormat from 'dayjs/plugin/customParseFormat'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(customParseFormat)
Vue.prototype.$dayjs = dayjs

Vue.filter('formatTime', (val) => dayjs(new Date(val)).format('DD/MM/YY HH:mm'))
Vue.filter('formatTimeOnly', (val) => dayjs(new Date(val)).format('HH:mm'))
