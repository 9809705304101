import HttpRequest from './HttpRequest'

class AuthProvider extends HttpRequest {
  constructor () {
    super(process.env.VUE_APP_API)
  }

  getTokenFromCode = (payload, config) => this.post('/sessions/get_token', { payload }, config)
    .catch((err) => this.handleError(err))

  getCsrfToken = (payload, config) => this.get('/get_csrf_token', { payload }, config)
    .catch((err) => this.handleError(err))

  getTestCsrf = (payload, config) => this.post('/test_csrf_token', { payload }, config)
    .catch((err) => this.handleError(err))

  currentUser = (config) => this.get('/current_user_detail', {}, config)
    .catch((err) => this.handleError(err))

  // solve 302 redirect
  logout = (config) => this.delete(
    '/users/sign_out',
    {},
    config
  )
}

export default new AuthProvider()
