import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    iconfont: 'mdiSvg'
  },
  theme: {
    light: true,
    themes: {
      light: {
        primary: '#1BA7E1',
        error: '#DF6C4F',
        success: '#2AB59C',
        warning: '#EDA437',
        secondary: '#6C6C6C',
        background: '#F4F6FA'
      },
      dark: {
        primary: '#1BA7E1',
        error: '#F04D4D',
        success: '#2AB59C',
        warning: '#EDA437',
        secondary: '#FFFFFF'
      }
    },
    options: { customProperties: true }
  }
})
