import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import vuetify from '@/plugins/Vuetify'

import '@/plugins/Day'
import '@/plugins/VueVirtualScrollList'
import '@/plugins/VueActionCable'
import '@/plugins/GlobalComponents'
import '@/plugins/VueInfiniteLoading'
import '@/plugins/lodash'
import '@/plugins/VueI18n'
import '@/plugins/VueUploadComponent'

Vue.config.productionTip = false

Vue.filter('formatNumber', (val) => Number(val).toFixed(2))

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App)
}).$mount('#app')
