import HttpRequest from './HttpRequest'

class ShopProvider extends HttpRequest {
  constructor () {
    super(process.env.VUE_APP_API)
  }

  fetchShopInfo (pageId) {
    return this.get(`/pages/${pageId}/chats/shop_info`)
      .catch((err) => this.handleError(err))
  }
}

export default new ShopProvider()
