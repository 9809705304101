<template>
  <v-app
    :class="{
      'theme-grayscale-1': isBrightness === 1,
      'theme-grayscale-2': isBrightness === 2
    }"
    light>
    <v-main>
      <router-view />
    </v-main>
    <overlay-loading />
    <snackbar
      v-bind="snackbar"
      @input="onSnackbarChange($event)" />
    <modal
      v-bind="confirmDialog"
      @onConfirm="onDialogChange($event)"
      @onCancel="onDialogChange($event)" />
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import OverlayLoading from './components/Layout/OverlayLoading.vue'

export default {
  name: 'App',
  components: {
    OverlayLoading
  },
  computed: {
    ...mapGetters({
      snackbar: 'Notification/snackbar',
      confirmDialog: 'Notification/confirmDialog',
      isBrightness: 'Theme/isBrightness'
    })
  },
  methods: {
    ...mapActions({
      closeErrorMessage: 'Notification/closeErrorMessage',
      setConfirmDialog: 'Notification/setConfirmDialog'
    }),
    onSnackbarChange (value) {
      if (!value) {
        this.closeErrorMessage()
      }
    },
    onDialogChange (value) {
      this.setConfirmDialog({
        active: false,
        value
      })
    }
  }
}
</script>

<style lang="scss">
  @import '~@/assets/scss/styles.scss';
  @import '~@/assets/scss/vuetify.scss';
</style>
