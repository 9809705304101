import humps from 'humps'
import store from '@/store'
import HttpRequest from './HttpRequest'

class ChatProvider extends HttpRequest {
  constructor () {
    super(process.env.VUE_APP_API)
  }

  getShipnityInfo ({ pageId }, payload, config) {
    return this.post(`/pages/${pageId}/chats/shipnity_info`, { payload }, config).catch((err) => this.handleError(err))
  }

  getChats = ({ pageId }, payload, config) => this.get(
      `/pages/${pageId}/chats.json`, { payload }, config
    ).catch((err) => this.handleError(err));

  chatMarkAsRead = ({ pageId, customerId }, payload, config) => this.post(
      `/pages/${pageId}/chats/${customerId}/read`, { payload }, config
    ).catch((err) => this.handleError(err));

  updateTag = ({ pageId, customerId }, payload, config) => this.post(
      `/pages/${pageId}/chats/${customerId}/update_tag`,
      { payload },
      config
    ).catch((err) => this.handleError(err));

  fetchConversation = ({ pageId, threadId }, payload, config) => this.get(
      `/pages/${pageId}/chats/${threadId}/conversation.json`,
      { payload },
      config
    ).catch((err) => this.handleError(err));

  findConversation = ({ pageId, customerId }, payload = {}, config) => {
    const platform = store.getters['Chat/platform'] === 'instagram' ? 'instagram' : 'facebook'
    if (platform === 'instagram') {
      // eslint-disable-next-line no-param-reassign
      payload.platform = 'instagram'
    }
    return this.get(
      `/pages/${pageId}/chats/${customerId}/find_conversation`,
      { payload },
      config
    ).catch((err) => this.handleError(err))
  }

  sendMessage = ({ pageId, customerId }, payload, config) => {
    const platform = store.getters['Chat/platform'] === 'instagram' ? 'instagram' : 'facebook'
    if (platform === 'instagram') {
      // eslint-disable-next-line no-param-reassign
      payload.platform = 'instagram'
    }
    return this.post(
      `/pages/${pageId}/chats/${customerId}/send_message`,
      { payload },
      config
    ).catch((err) => this.handleError(err))
  }

  sendFile = ({ pageId, customerId }, payload, config) => {
    const formData = new FormData()
    Object.keys(payload).forEach((key) => {
      const value = key !== 'file' ? humps.decamelizeKeys(payload[key]) : payload[key]
      formData.append(humps.decamelize(key), value)
    })
    const platform = store.getters['Chat/platform'] === 'instagram' ? 'instagram' : 'facebook'
    if (platform === 'instagram') {
      formData.append('platform', platform)
    }
    return this.post(
      `/pages/${pageId}/chats/${customerId}/send_message`,
      {
        payload: formData,
        decamelize: false
      },
      {
        ...config,
        headers: { 'Content-Type': 'multipart/form-data' }
      }
    ).catch((err) => this.handleError(err, 'อัพโหลดรูปภาพไม่สำเร็จ'))
  };

  deleteQuickReply = ({ pageId }, payload, config) => this.delete2(
    `/pages/${pageId}/chats/delete_quick_reply`,
    { payload },
    config
  ).catch((err) => this.handleError(err))

  updateQuickReply = ({ pageId }, payload, config) => this.post(
    `/pages/${pageId}/chats/update_quick_reply`,
    { payload },
    config
  ).catch((err) => this.handleError(err));

  verifySlip = ({ pageId }, payload, config) => this.post(
    `/pages/${pageId}/chats/payment_slip_info`, { payload }, config
  ).catch((err) => this.handleError(err));

  getInstagramAvatar = ({ pageId, username }, payload, config) => this.get(
      `/pages/${pageId}/chats/${username}/instagram_profile_picture`, { payload }, config
  ).catch((err) => this.handleError(err));

  getBroadcast = ({ pageId }, payload, config) => this.get(
      `/pages/${pageId}/ntn_settings.json`, { payload }, config
  ).catch((err) => this.handleError(err));

  updateBroadcast = ({ pageId }, payload, config) => this.put(
      `/pages/${pageId}/ntn_settings`, { payload }, config
  ).catch((err) => this.handleError(err));

  updateBroadcastWithFile = ({ pageId }, payload, config) => {
    const formData = new FormData()
    let hasFile = false
    Object.keys(payload).forEach((key) => {
      if (key !== 'image') {
        formData.append(humps.decamelize(key), humps.decamelizeKeys(payload[key]))
      } else if (payload[key]) {
        hasFile = true
        formData.append(humps.decamelize(key), payload[key])
      }
    })
    return this.put(
      `/pages/${pageId}/ntn_settings`,
      {
        payload: formData,
        decamelize: false
      },
      {
        ...config,
        ...{
          headers: hasFile ? { 'Content-Type': 'multipart/form-data' } : undefined
        }
      }
    ).catch((err) => this.handleError(err, 'อัพโหลดรูปภาพไม่สำเร็จ'))
  };

  sendBroadcastWithFile = ({ pageId, customerId }, payload, config) => {
    const formData = new FormData()
    Object.keys(payload).forEach((key) => {
      const value = key !== 'image' ? humps.decamelizeKeys(payload[key]) : payload[key]
      if (!(!value && key === 'image')) {
        formData.append(humps.decamelize(key), value)
      }
    })
    return this.post(
      `/pages/${pageId}/chats/${customerId}/send_broadcast`,
      {
        payload: formData,
        decamelize: false
      },
      {
        ...config,
        headers: { 'Content-Type': 'multipart/form-data' }
      }
    ).catch((err) => this.handleError(err, 'อัพโหลดรูปภาพไม่สำเร็จ'))
  };

  sendBroadcast = ({ pageId, customerId }, payload, config) => this.post(
      `/pages/${pageId}/chats/${customerId}/send_broadcast`, { payload }, config
  ).catch((err) => this.handleError(err));

  testBroadcast = ({ pageId }, { fors, msgCount }) => this.post(`/test_broadcast/${pageId}`, {
    payload: {
      fors,
      msgCount
    }
  }).catch((err) => this.handleError(err));
}

export default new ChatProvider()
