const defaultState = {
  isChatLoading: false,
  shopInfo: {},
  pages: [],
  page: {},
  stageId: 0, // status 0 = จัดส่ง, 1 = รอพิมพ์, 2 = รอแพ็ค, 3 = ส่งแล้ว
  filterId: 0,
  isCustomerListAlert: true,
  firstCustomerList: null,
  customerList: {},
  customers: {},
  searchChat: '',
  nextCursor: null,
  administrators: {},
  threadId: null,
  newMessages: [],
  // selectedChat: {},
  nextCusorMessage: '',
  customerMessages: [],
  hasPayment: false,
  summaryText: '', // คำสั่งซื้อ
  stage: 0, // 0 = จัดส่ง, 1 = รอพิมพ์, 2 = รอแพ็ค, 3 = ส่งแล้ว
  tab: 0, // 0 = สินค้า, 1 = ที่อยู่, 2 = ชำระเงิน, 3 = จัดส่ง
  preOrder: false,
  taxAble: false,
  sendBill: false,
  orderClosed: false,
  stockId: null,
  orders: {},
  selectedProduct: {},
  selectedSetProduct: {},
  orderId: null,
  orderPaymentIndex: 0,
  promoCode: []
}

const mutations = {
  SET_ISCHATLOADING (state, payload) {
    state.isChatLoading = payload
  },
  SET_DEFAULT (state) {
    state.page = {}
    state.stageId = 0
    state.filterId = 0
    state.isCustomerListAlert = true
    state.customerList = {}
    state.customers = {}
    state.searchChat = ''
    state.nextCursor = null
    state.administrators = {}
    state.threadId = null
    state.newMessages = []
    state.nextCusorMessage = ''
    state.customerMessages = []
    state.hasPayment = false
    state.summaryText = ''
    state.stage = 0
    state.tab = 0
    state.preOrder = false
    state.taxAble = false
    state.sendBill = false
    state.orderClosed = false
    state.stockId = null
    state.orders = {}
    state.selectedProduct = {}
    state.selectedSetProduct = {}
    state.orderId = null
    state.orderPaymentIndex = 0
  },
  SET_SHOP_INFO (state, payload) {
    state.shopInfo = payload
  },
  SET_PAGE (state, payload) {
    state.page = payload
  },
  SET_PAGES (state, payload) {
    state.pages = payload
  },
  SET_STAGE_ID (state, payload) {
    state.stageId = payload
  },
  SET_FILTER_ID (state, payload) {
    state.filterId = payload
  },
  SET_SEARCH_CHAT (state, payload) {
    state.searchChat = payload
  },
  SET_IS_CUSTOMER_LIST_ALERT (state, payload) {
    state.isCustomerListAlert = payload
    if (!state.isCustomerListAlert) {
      const customerList = Object.keys(state.customerList)
      state.firstCustomerList = customerList.length ? customerList[0] : null
    }
  },
  SET_CUSTOMER_LIST (state, payload) {
    state.customerList = payload
  },
  ALERT_CUSTOMER_LIST (state, payload) {
    state.customerList = {
      [payload.id]: {},
      ...state.customerList,
      [payload.id]: payload
    }
  },
  SET_CUSTOMERS (state, payload) {
    state.customers = payload
  },
  SET_CUSTOMER (state, payload) {
    state.customers = {
      ...state.customers,
      [payload.id]: {
        tags: [],
        tagsWithColor: [],
        administrators: [],
        ...state.customers[payload.id],
        ...payload
      }
    }
  },
  SET_NEXT_CURSOR (state, payload) {
    state.nextCursor = payload
  },
  SET_ADMINISTRATOR (state, payload) {
    state.administrators = {
      ...state.administrators,
      [payload.id]: {
        ...state.administrators[payload.id],
        ...payload
      }
    }
  },
  SET_THREADID (state, payload) {
    state.threadId = payload
  },
  ADD_NEW_MESSAGE (state, payload) {
    const message = payload
    const customer = state.customers[message.for] || { tags: [], tagsWithColor: [] }
    customer.snippet = !message.result.attachments ? message.result.message : 'ข้อความรูปภาพ'
    customer.updatedTime = message.result.createdTime || this.$dayjs().format()
    customer.readAt = null
    customer.message = {
        id: message.id,
        mid: message.mid,
        attachments: message.result.attachments,
        base64: message.result.base64,
        from: {
          ...message.result.from,
          name: customer.name
        },
        message: message.result.message,
        createdTime: message.result.createdTime,
        status: message.status,
        error: message.error
    }

    customer.unreadCount = state.customers[message.for] ? state.customers[message.for].unreadCount + 1 : 1
    customer.readAt = null
    state.customers = {
      [message.for]: {},
      ...state.customers,
      [message.for]: customer
    }

    const customerListFor = state.customerList[message.for]
    const customerFor = state.customers[message.for]
    let orderStatusTab = null
    if (state.stageId === 1) {
      orderStatusTab = 0
    } else if (state.stageId === 2) {
      orderStatusTab = 1
    } else if (state.stageId === 3) {
      orderStatusTab = 2
    } else if (state.stageId === 4) {
      orderStatusTab = 3
    }
    if (orderStatusTab === null || orderStatusTab === customerFor.orderStatus) {
      if (state.isCustomerListAlert || !customerListFor) {
        state.customerList = {
          [message.for]: {
            id: message.for
          },
          ...state.customerList
        }
      } else {
        state.customerList = {
          ...state.customerList,
          [message.for]: {
            id: message.for
          }
        }
      }
    }
  },
  READ_NEW_MESSAGE (state, payload) {
    state.newMessages = state.newMessages.filter((i) => i.id !== payload.id)
  },
  UNREAD_NEW_MESSAGE (state, payload) {
    state.newMessages = state.newMessages.filter((i) => i.id !== payload.id)
  },
  SET_SELECTED_CHAT (state, payload) {
    state.selectedChat = payload
  },
  SET_NEXT_CURSOR_MESSAGE (state, payload) {
    state.nextCursorMessage = payload
  },
  ADD_CUSTOMER_MESSAGES (state, payload) {
    state.customerMessages = [
      ...state.customerMessages,
      ...payload
    ]
  },
  SET_MESSAGES (state, payload) {
    state.customerMessages = payload
  },
  SET_STEP (state, payload) {
    state.step = payload
  },
  SET_HASPAYMENT (state, payload) {
    state.hasPayment = payload
  },
  SET_SUMMARY_TEXT (state, payload) {
    state.summaryText = payload
  },
  SET_STAGE (state, payload) {
    state.stage = payload
  },
  SET_TAB (state, payload) {
    state.tab = payload
  },
  SET_PREORDER (state, payload) {
    state.preOrder = payload
  },
  SET_TAXABLE (state, payload) {
    state.taxAble = payload
  },
  SET_SENDBILL (state, payload) {
    state.sendBill = payload
  },
  SET_ORDERCLOSED (state, payload) {
    state.orderClosed = payload
  },
  SET_STOCKID (state, payload) {
    state.stockId = payload
  },
  ADD_PURCHASES (state, payload) {
    const checkExisted = state.orders[payload.id].purchases.findIndex(
      (item) => item.subproductId === payload.product.subproductId
    )
    if (checkExisted > -1) {
      state.orders[payload.id].purchases[checkExisted].quantity++
    } else {
      state.orders[payload.id].purchases.push(payload.product)
    }
  },
  ADD_SETPURCHASES (state, payload) {
    const checkExisted = state.orders[payload.id].setPurchases.findIndex(
      (item) => item.productSetId === payload.product.productSetId
    )
    if (checkExisted > -1) {
      state.orders[payload.id].setPurchases[checkExisted].quantity++
    } else {
      state.orders[payload.id].setPurchases.push(payload.product)
    }
  },
  SET_ORDER (state, payload) {
    state.orders = {
      ...state.orders,
      [payload.id]: {
        ...state.orders[payload.id],
        ...payload
      }
    }
  },
  SET_SELECTED_PRODUCT (state, payload) {
    state.selectedProduct = payload
  },
  SET_SELECTED_SETPRODUCT (state, payload) {
    state.selectedSetProduct = payload
  },
  SET_IMAGE (state, payload) {
    state.orders[payload.id].paymentSlip = payload.url
  },
  SET_ORDER_ID (state, payload) {
    state.orderId = payload
  },
  SET_ORDER_PAYMENT (state, payload) {
    state.orders[payload.id].orderPayments.push(payload.payment)
  },
  SET_SUB_PAYMENT_IMAGE (state, payload) {
    state.orders[payload.id].orderPayments[payload.index].paymentSlip = payload.url
  },
  SET_ORDER_PAYMENT_INDEX (state, payload) {
    state.orderPaymentIndex = payload
  },
  DELETE_ORDER_PAYMENT (state, payload) {
    const payment = state.orders[payload.id].orderPayments[payload.index]

    if (payment.id) {
      state.orders[payload.id].orderPayments[payload.index]._destroy = 1
    } else {
      state.orders[payload.id].orderPayments.splice(payload.index, 1)
    }
  },
  DELETE_SELECTED_PURCHASES (state, payload) {
    const purchases = state.orders[payload.id].purchases[payload.index]
    if (purchases.id) {
      state.orders[payload.id].purchases[payload.index]._destroy = 1
    } else {
      state.orders[payload.id].purchases.splice(payload.index, 1)
    }
  },
  DELETE_SELECTED_SETPURCHASES (state, payload) {
    const setPurchases = state.orders[payload.id].setPurchases[payload.index]
    if (setPurchases.id) {
      state.orders[payload.id].setPurchases[payload.index]._destroy = 1
    } else {
      state.orders[payload.id].setPurchases.splice(payload.index, 1)
    }
  },
  SET_EDIT_PAYMENT (state, payload) {
    state.orders[payload.id].orderPayments[payload.index].canEdit = payload.state
  },
  SET_PROMOCODE (state, payload) {
    state.promoCode = payload
  },
  SET_SELECT_PROMOCODE (state, payload) {
    state.orders[payload.id].promoCode = payload.promocode
  }
}

const actions = {
  setIsChatLoading ({ commit }, payload) {
    commit('SET_ISCHATLOADING', payload)
  },
  setDefault ({ commit }, payload) {
    commit('SET_DEFAULT', payload)
  },
  setShopInfo ({ commit }, payload) {
    commit('SET_SHOP_INFO', payload)
  },
  setPage ({ commit }, payload) {
    commit('SET_PAGE', payload)
  },
  setPages ({ commit }, payload) {
    commit('SET_PAGES', payload)
  },
  setStageID ({ commit }, payload) {
    commit('SET_STAGE_ID', payload)
  },
  setFilterID ({ commit }, payload) {
    commit('SET_FILTER_ID', payload)
  },
  setSearchChat ({ commit }, payload) {
    commit('SET_SEARCH_CHAT', payload)
  },
  setIsCustomerListAlert ({ commit }, payload) {
    commit('SET_IS_CUSTOMER_LIST_ALERT', payload)
  },
  setCustomerList ({ commit }, payload) {
    commit('SET_CUSTOMER_LIST', payload)
  },
  alertCustomerList ({ commit }, payload) {
    commit('ALERT_CUSTOMER_LIST', payload)
  },
  clearCustomers ({ commit }) {
    commit('CLEAR_CUSTOMERS')
  },
  setCustomers ({ commit }, payload) {
    commit('SET_CUSTOMERS', payload)
  },
  setCustomer ({ commit }, payload) {
    commit('SET_CUSTOMER', payload)
  },
  // alertCustomer ({ commit }, payload) {
  //   commit('ALERT_CUSTOMER', payload)
  // },
  setNextCursor ({ commit }, payload) {
    commit('SET_NEXT_CURSOR', payload)
  },
  setAdministrator ({ commit }, payload) {
    commit('SET_ADMINISTRATOR', payload)
  },
  setThreadId ({ commit }, payload) {
    commit('SET_THREADID', payload)
  },
  addNewMessage ({ commit }, payload) {
    commit('ADD_NEW_MESSAGE', payload)
  },
  readNewMessage ({ commit }, payload) {
    commit('READ_NEW_MESSAGE', payload)
  },
  unreadNewMessage ({ commit }, payload) {
    commit('UNREAD_NEW_MESSAGE', payload)
  },
  // setSelectedChat ({ commit }, payload) {
  //   commit('SET_SELECTED_CHAT', payload)
  // },
  setNextCursorMessage ({ commit }, payload) {
    commit('SET_NEXT_CURSOR_MESSAGE', payload)
  },
  addCustomerMessages ({ commit }, payload) {
    commit('ADD_CUSTOMER_MESSAGES', payload)
  },
  setMessages ({ commit }, payload) {
    commit('SET_MESSAGES', payload)
  },
  setStep ({ commit }, payload) {
    commit('SET_STEP', payload)
  },
  setHasPayment ({ commit }, payload) {
    commit('SET_HASPAYMENT', payload)
  },
  setSummaryText ({ commit }, payload) {
    commit('SET_SUMMARY_TEXT', payload)
  },
  setStage ({ commit }, payload) {
    commit('SET_STAGE', payload)
  },
  setTab ({ commit }, payload) {
    commit('SET_TAB', payload)
  },
  setPreOrder ({ commit }, payload) {
    commit('SET_PREORDER', payload)
  },
  setTaxAble ({ commit }, payload) {
    commit('SET_TAXABLE', payload)
  },
  setSendBill ({ commit }, payload) {
    commit('SET_SENDBILL', payload)
  },
  setOrderClosed ({ commit }, payload) {
    commit('SET_ORDERCLOSED', payload)
  },
  setStockId ({ commit }, payload) {
    commit('SET_STOCKID', payload)
  },
  addPurchases ({ commit }, payload) {
    commit('ADD_PURCHASES', payload)
  },
  addSetPurchases ({ commit }, payload) {
    commit('ADD_SETPURCHASES', payload)
  },
  setOrder ({ commit }, payload) {
    commit('SET_ORDER', payload)
  },
  setSelectedProduct ({ commit }, payload) {
    commit('SET_SELECTED_PRODUCT', payload)
  },
  setSelectedSetProduct ({ commit }, payload) {
    commit('SET_SELECTED_SETPRODUCT', payload)
  },
  setImage ({ commit }, payload) {
    commit('SET_IMAGE', payload)
  },
  setOrderId ({ commit }, payload) {
    commit('SET_ORDER_ID', payload)
  },
  setOrderPayment ({ commit }, payload) {
    commit('SET_ORDER_PAYMENT', payload)
  },
  deleteOrderPayment ({ commit }, payload) {
    commit('DELETE_ORDER_PAYMENT', payload)
  },
  setSubPaymentImage ({ commit }, payload) {
    commit('SET_SUB_PAYMENT_IMAGE', payload)
  },
  setOrderPaymentIndex ({ commit }, payload) {
    commit('SET_ORDER_PAYMENT_INDEX', payload)
  },
  setEditPayment ({ commit }, payload) {
    commit('SET_EDIT_PAYMENT', payload)
  },
  setPromoCode ({ commit }, payload) {
    commit('SET_PROMOCODE', payload)
  },
  setSelectPromoCode ({ commit }, payload) {
    commit('SET_SELECT_PROMOCODE', payload)
  },
  deleteSelectedPurchases ({ commit }, payload) {
    commit('DELETE_SELECTED_PURCHASES', payload)
  },
  deleteSelectedSetPurchases ({ commit }, payload) {
    commit('DELETE_SELECTED_SETPURCHASES', payload)
  }
}

const getters = {
  isChatLoading: (state) => state.isChatLoading,
  shopInfo: (state) => state.shopInfo,
  page: (state) => state.page,
  pages: (state) => state.pages,
  pageId: (state) => state.page.uid,
  platform: (state) => state.page.platform,
  stageId: (state) => state.stageId,
  filterId: (state) => state.filterId,
  searchChat: (state) => state.searchChat,
  isCustomerListAlert: (state) => state.isCustomerListAlert,
  firstCustomerList: (state) => state.firstCustomerList,
  customerList: (state) => state.customerList,
  customers: (state) => state.customers,
  nextCursor: (state) => state.nextCursor,
  administrators: (state) => state.administrators,
  threadId: (state) => state.threadId,
  newMessages: (state) => state.newMessages,
  nextCusorMessage: (state) => state.nextCusorMessage,
  customerMessages: (state) => state.customerMessages,
  step: (state) => state.step,
  hasPayment: (state) => state.hasPayment,
  summaryText: (state) => state.summaryText,
  stage: (state) => state.stage,
  tab: (state) => state.tab,
  preOrder: (state) => state.preOrder,
  taxAble: (state) => state.taxAble,
  sendBill: (state) => state.sendBill,
  orderClosed: (state) => state.orderClosed,
  stockId: (state) => state.stockId,
  orders: (state) => state.orders,
  getOrder: (state) => (id) => state.orders[id] || {},
  selectedProduct: (state) => state.selectedProduct,
  selectedSetProduct: (state) => state.selectedSetProduct,
  orderId: (state) => state.orderId,
  orderPaymentIndex: (state) => state.orderPaymentIndex,
  getPromoCode: (state) => state.promoCode
}

export default {
  namespaced: true,
  state: defaultState,
  actions,
  mutations,
  getters
}
