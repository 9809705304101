const defaultState = {
  snackbar: {
    type: null,
    value: false,
    message: 'No message.',
    timeout: 5000
  },
  confirmDialog: {
    confirmType: null,
    confirmOnly: false,
    title: 'No title',
    message: 'No message.',
    confirmText: 'ตกลง',
    cancelText: 'ยกเลิก',
    active: false,
    uid: null,
    data: null
  },
  isLoading: false,
  isOrderLoading: false,
  isOverlay: false,
  drawerInbox: false
}

const mutations = {
  SET_SNACKBAR (state, payload) {
    state.snackbar = {
      ...defaultState.snackbar,
      ...state.snackbar,
      ...payload
    }
  },
  SET_CONFIRMDIALOG (state, payload) {
    state.confirmDialog = {
      ...defaultState.confirmDialog,
      ...state.confirmDialog,
      ...payload
    }
  },
  SET_LOADING (state, payload) {
    state.isLoading = payload
  },
  SET_ORDERLOADING (state, payload) {
    state.isOrderLoading = payload
  },
  SET_DRAWER_INBOX (state, payload) {
    state.drawerInbox = payload
  },
  SET_OVERLAY (state, payload) {
    state.isOverlay = payload
  }
}

const actions = {
  setDrawerInbox ({ commit }, payload) {
    commit('SET_DRAWER_INBOX', payload)
  },
  setOverlay ({ commit }, payload) {
    commit('SET_OVERLAY', payload)
  },
  setLoading ({ commit }, payload) {
    commit('SET_LOADING', payload)
  },
  setOrderLoading ({ commit }, payload) {
    commit('SET_ORDERLOADING', payload)
  },
  openLoading ({ commit }) {
    commit('SET_LOADING', true)
  },
  closeLoading ({ commit }) {
    commit('SET_LOADING', false)
  },
  showErrorMessage ({ commit }, payload) {
    commit('SET_SNACKBAR', {
      ...payload,
      value: true
    })
  },
  closeErrorMessage ({ commit }) {
    commit('SET_SNACKBAR', {
      value: false
    })
  },
  setConfirmDialog ({ commit, payload }) {
    commit('SET_CONFIRMDIALOG', payload)
  }
}

const getters = {
  snackbar: (state) => state.snackbar,
  confirmDialog: (state) => state.confirmDialog,
  isLoading: (state) => state.isLoading,
  isOrderLoading: (state) => state.isOrderLoading,
  isOverlay: (state) => state.isOverlay,
  drawerInbox: (state) => state.drawerInbox
}

export default {
  namespaced: true,
  state: defaultState,
  actions,
  mutations,
  getters
}
