<template>
  <v-overlay
    :value="isLoading"
    z-index="20">
    <v-progress-circular
      indeterminate
      size="64"
    />
  </v-overlay>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'OverlayLoading',
  computed: {
    ...mapGetters({
      isLoading: 'Notification/isOverlay'
    })
  }
}
</script>
