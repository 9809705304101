import Vue from 'vue'
import VueRouter from 'vue-router'
import AuthProvider from '@/resources/AuthProvider'
import ChatProvider from '@/resources/ChatProvider'
import store from '@/store'
import ShopProvider from '@/resources/ShopProvider'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Inbox',
    component: () => import('@/views/inbox/Inbox.vue'),
    children: [
      {
        path: 'pages/:pageId/messages/:customerId',
        name: 'CPages',
        component: () => import('@/views/inbox/pages/message/Message.vue')
      },
      {
        path: ':pageId/messages/:customerId',
        name: '_CPages',
        component: () => import('@/views/inbox/pages/message/Message.vue')
      },
      {
        path: 'pages/:pageId/messages/t/:threadId',
        name: 'TPages',
        component: () => import('@/views/inbox/pages/message/Message.vue')
      },
      {
        path: 'messages/:customerId',
        name: 'Messages',
        component: () => import('@/views/inbox/pages/message/Message.vue')
      }
    ]
  }
]
const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach(async (to, from, next) => {
  if (process.env.VUE_APP_MODE !== 'LOCAL') {
    const crsf = await AuthProvider.getCsrfToken()
    store.dispatch('Auth/setCsrf', crsf.token)
  }
  const user = await AuthProvider.currentUser()
  if (user.result.name !== null) {
    store.dispatch('Auth/setUsername', user.result.name)
  } else {
    window.location.href = process.env.VUE_APP_WHALEBOX
  }
  if (to.name === 'TPages') {
    const pageId = to.params.pageId
    const info = await ChatProvider.getShipnityInfo({ pageId }, {
      chats: [to.params.threadId]
    })
    if (info.result.length) {
      const { facebookId } = info.result[0]
      let redirect = `/pages/${pageId}/messages/${facebookId}`
      const platform = to.query.platform || ''
      if (platform) {
        redirect += `?platform=${platform}`
      }
      window.location.href = redirect
    } else {
      window.location.href = '/'
    }
  } else if (to.name === 'Messages') {
    store.dispatch('Chat/setTab', 0)
    store.dispatch('Chat/setCustomer', {
      id: to.params.customerId,
      unreadCount: 0
    })
    next()
  } else {
    store.dispatch('Notification/setOverlay', true)
    const pageId = to.params.pageId || store.getters['Chat/pageId']
    const platform = store.getters['Chat/platform'] || to.query.platform || 'messenger'
    const { page, pages } = await ChatProvider.getChats({ pageId })
    const allPages = []
    pages.forEach((item) => {
      allPages.push({
        ...item,
        subscribeId: item.uid,
        isInstagram: false,
        platform: 'messenger',
        platformKey: `messenger_${item.uid}`
      })
      if (item.instagramId) {
        allPages.push({
          ...item,
          subscribeId: item.instagramId,
          isInstagram: true,
          platform: 'instagram',
          platformKey: `instagram_${item.instagramId}`
        })
      }
    })
    const currentPage = allPages.find((item) => item.uid === page.uid && item.platform === platform)
    const shop = await ShopProvider.fetchShopInfo(page.id)
    store.dispatch('Chat/setPage', currentPage)
    store.dispatch('Chat/setPages', allPages)
    store.dispatch('Chat/setShopInfo', shop)
    store.dispatch('Notification/setOverlay', false)
    next()
  }
})

export default router
