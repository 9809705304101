const state = {
  token: 'nHaq2VjKiEvAlFdvIHxjVtiInBoAZhaAtE/oUzaTH9kUbtfJ5KkCVZGRRnzLL6CobvKaf4NAUhlUIGcwxFR/Xg==',
  userId: '8',
  // token: 'hO8g2rcIim3i80If+QwIsEeL3m824Dmr+894gXypUCIsOuKtQy6BcQYDGjS0tEnzd+TtB7U7Oq2X0YmP7373og==',
  // userId: '1',
  // token: 'IA+swGr/+3NdlNYZxtubzZPy+Gco44Jmp7fI4quYmffan45dxI309I7JUmZyJQn1qrFySVa8UKzptUO9hfUhvg==',
  // userId: '1',
  // token: 'PD7xYlPXKJcLmm+T0Qyzzwuw5vijaXAydGB01dyTqpHzJeMi7wfo2oTIGTDrGdD3oVhYRUHUBDyRN1O2enyWZA==',
  // userId: '2',
  csrf: null,
  username: null

}

const mutations = {
  SET_AUTH (state, payload) {
    state.auth = payload
  },
  SET_USERNAME (state, payload) {
    state.username = payload
  },
  SET_CSRF (state, payload) {
    state.csrf = payload
  }
}

const actions = {
  setUsername ({ commit }, payload) {
    commit('SET_USERNAME', payload)
  },
  setAuth ({ commit }, payload) {
    commit('SET_AUTH', payload)
  },
  setCsrf ({ commit }, payload) {
    commit('SET_CSRF', payload)
  }
}

const getters = {
  token: (state) => state.token,
  userId: (state) => state.userId,
  csrf: (state) => state.csrf,
  username: (state) => state.username
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
