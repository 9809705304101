import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import AuthModule from '@/store/modules/AuthModule'
import ChatModule from '@/store/modules/ChatModule'
import ThemeModule from '@/store/modules/ThemeModule'
import NotificationModule from '@/store/modules/NotificationModule'

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  modules: ['Theme']
})

export default new Vuex.Store({
  modules: {
    Auth: AuthModule,
    Chat: ChatModule,
    Theme: ThemeModule,
    Notification: NotificationModule
  },
  plugins: [
    vuexLocal.plugin
  ]
})
